import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Chip,
  CircularProgress,
  FormHelperText,
  Dialog, DialogActions, DialogContent, DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import RepeticionControl from "./components/RepeticionControl";
import { addNotificacion, getEmpresas, getSucursales } from "../../services/api";
import MessageInput from './components/MessageInput';
import showToast from '../../services/toast';

const AddNotificacion = () => {
  const [tipoRepeticion, setTipoRepeticion] = useState("");
  const [repeticion, setRepeticion] = useState([]);
  const [repeticionTemp, setRepeticionTemp] = useState({ dia: "", hora: "" });
  const navigate = useNavigate();
  
  // Estado para manejar empresas y sucursales dinámicamente
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [newNotification, setNewNotification] = useState({
    message: '',
    title: '',
    fechaHoraEnvio: '',
  });


  // Fetch companies on mount
  useEffect(() => {
    const fetchCompanies = async () => {
      setLoadingCompanies(true);
      try {
        const response = await getEmpresas();
        setCompanies(
          response.data.titulares.map((company) => ({
            id: company.ruc_titular,
            nombre: company.nombre_comercial,
          }))
        );
      } catch (error) {
        console.error("Error fetching companies:", error);
      } finally {
        setLoadingCompanies(false);
      }
    };

    fetchCompanies();
  }, []);

  // Fetch branches when a company is selected
  useEffect(() => {
    if (selectedCompany) {
      const fetchBranches = async () => {
        setLoadingBranches(true);
        try {
          const response = await getSucursales(selectedCompany);
          setBranches(
            response.data.sucursales.map((branch) => ({
              id: branch.cod_sucursal,
              nombre: branch.nom_sucursal,
            }))
          );
        } catch (error) {
          console.error("Error fetching branches:", error);
        } finally {
          setLoadingBranches(false);
        }
      };

      fetchBranches();
    }
  }, [selectedCompany]);

  const handleAddRepeticion = () => {
    if (repeticionTemp.dia && repeticionTemp.hora) {
      setRepeticion([...repeticion, { dia: repeticionTemp.dia, hora: repeticionTemp.hora }]);
      setRepeticionTemp({ dia: "", hora: "" });
    }
  };

  const handleDeleteRepeticion = (index) => {
    setRepeticion(repeticion.filter((_, i) => i !== index));
  };

  const validateForm = () => {
    const errors = {};
    if (!selectedCompany) errors.company = 'La empresa es requerida';
    if (selectedBranches.length === 0) errors.branches = 'Al menos una sucursal es requerida';
    if (!newNotification.title) errors.title = 'El título es requerido';
    if (!newNotification.message) errors.message = 'El mensaje es requerido';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm() ) {
      setOpenConfirmationModal(true);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleBranchChange = (event) => {
    setSelectedBranches(event.target.value);
  };

  const handleConfirmAddNotification = async () => {
    setLoadingConfirm(true);
    const notificationData = {
      all_empresas: false,
      empresa: selectedCompany,
      message_template: newNotification.message,
      sucursales: selectedBranches,
      title: newNotification.title,
      repeat_type: tipoRepeticion,
      send_time: repeticionTemp.hora,
      is_active: true,
      start_datetime: newNotification.fechaHoraEnvio,
    };

    if (tipoRepeticion === 'weekly') {
      notificationData.repeat_day_of_week = repeticionTemp.dia;
    } else if (tipoRepeticion === 'monthly') {
      notificationData.repeat_day_of_month = repeticionTemp.dia;
    } else if (tipoRepeticion === 'every_x_days') {
      notificationData.repeat_every_x_days = repeticionTemp.dia;
    }

    console.log('notificationData', notificationData);

    try {
      await addNotificacion(notificationData);
      console.log('Notificación agregada:', notificationData);
      showToast.success('Notificación agregada correctamente.');

      setNewNotification({
        message: '',
        fechaHoraEnvio: '',
        title: '',
      });
      setSelectedCompany('');
      setSelectedBranches([]);
      handleGoBack();
    } catch (error) {
      showToast.error('Ocurrio un error.');
      console.error('Error adding reminder:', error);
    } finally {
      setLoadingConfirm(false);
      setOpenConfirmationModal(false);
    }
  };

  return (
    <Container style={{ marginLeft: "0", maxWidth: "100%", display: "grid" }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="16px">
        <Box display="flex" alignItems="center">
          <IconButton onClick={handleGoBack} style={{ marginRight: "16px" }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Agregar notificación
          </Typography>
        </Box>
        <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
          Guardar
        </Button>
      </Box>
      <form onSubmit={handleSubmit} style={{ marginTop: "24px" }}>
        <Grid container spacing={3}>
          {/* Columna Derecha */}
          <Grid item xs={12} md={6} className="grid-left">
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={newNotification.title}
              onChange={(e) => setNewNotification({ ...newNotification, title: e.target.value })}
              style={{ marginBottom: "16px" }}
            />
            <TextField
              label="Fecha y Hora de Envío"
              type="datetime-local"
              variant="outlined"
              fullWidth
              value={newNotification.fechaHoraEnvio}
              onChange={(e) => setNewNotification({ ...newNotification, fechaHoraEnvio: e.target.value })}
              style={{ marginBottom: "16px" }}
              InputLabelProps={{ shrink: true }}
            />
            <FormControl fullWidth style={{ marginBottom: "16px" }} required error={Boolean(formErrors.company)}>
              <InputLabel>Empresa</InputLabel>
              <Select
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled></MenuItem>
                {loadingCompanies ? (
                  <MenuItem disabled>
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : (
                  companies.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.nombre}
                    </MenuItem>
                  ))
                )}
              </Select>
              <FormHelperText>{formErrors.company}</FormHelperText>
            </FormControl>

            {selectedCompany && (
              <FormControl fullWidth style={{ marginBottom: "16px" }} required error={Boolean(formErrors.branches)}>
                <InputLabel>Sucursal(es)</InputLabel>
                <Select multiple value={selectedBranches} onChange={handleBranchChange} displayEmpty required>
                  <MenuItem value="" disabled></MenuItem>
                  {loadingBranches ? (
                    <MenuItem disabled>
                      <CircularProgress size={24} />
                    </MenuItem>
                  ) : (
                    branches.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>
                        {branch.nombre}
                      </MenuItem>
                    ))
                  )}
                </Select>
                <FormHelperText>{formErrors.branches}</FormHelperText>
              </FormControl>
            )}

            {selectedBranches.length > 0 && (
              <Box style={{ marginBottom: "24px" }}>
                <Typography variant="subtitle1">Sucursales seleccionadas:</Typography>
                {selectedBranches.map((branchId) => {
                  const branch = branches.find((branch) => branch.id === branchId);
                  return branch ? (
                    <Chip key={branch.id} label={branch.nombre} style={{ marginRight: "8px" }} />
                  ) : null;
                })}
              </Box>
            )}

            <RepeticionControl
              tipoRepeticion={tipoRepeticion}
              repeticion={repeticion}
              setRepeticion={setRepeticion}
              repeticionTemp={repeticionTemp}
              setRepeticionTemp={setRepeticionTemp}
              handleAddRepeticion={handleAddRepeticion}
              setTipoRepeticion={setTipoRepeticion}
            />

            {repeticion.length > 0 && (
              <Typography variant="subtitle2" style={{ marginTop: "16px" }}>
                Repeticiones
              </Typography>
            )}
            <List>
              {repeticion.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`${item.dia}, ${item.hora}`} />
                  <IconButton onClick={() => handleDeleteRepeticion(index)} edge="end">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Grid>

          {/* Columna Izquierda */}
          <Grid item xs={12} md={6}>
            <MessageInput
              message={newNotification.message}
              setMessage={(message) => setNewNotification({ ...newNotification, message })}
              helperText={formErrors.message}
              error={Boolean(formErrors.message)}
            />
          </Grid>
        </Grid>
      </form>

      {/* Confirmation Modal */}
      <Dialog
      open={openConfirmationModal}
      onClose={() => setOpenConfirmationModal(false)}
    >
      <DialogTitle>
        <Typography variant="h5">
          Confirmar
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          ¿Está seguro de que desea agregar esta notificacion?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenConfirmationModal(false)}
          className="cancel-button"
          variant="outlined"
          disabled={loadingConfirm}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleConfirmAddNotification}
          className="confirm-button"
          variant="contained"
          disabled={loadingConfirm}
        >
          {loadingConfirm ? <CircularProgress size={24} color="inherit" /> : 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
    </Container>
  );
};

export default AddNotificacion;
