import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getNotificacion, activarNotificacion, desactivarNotificacion } from '../../services/api'; // Importa los servicios
import { Box, Typography, Card, CardContent, Grid, Divider, Chip, CircularProgress, IconButton, Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const NotificacionDetalle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [notificacion, setNotificacion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(false); // Estado para el loader del botón

  useEffect(() => {
    const fetchNotificacion = async () => {
      try {
        const response = await getNotificacion(id);
        setNotificacion(response.data);
      } catch (error) {
        setError('Error fetching notificación');
      } finally {
        setLoading(false);
      }
    };

    fetchNotificacion();
  }, [id]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const toggleNotificacionStatus = async () => {
    setLoadingStatus(true); // Activar el loader
    try {
      if (notificacion.is_active) {
        await desactivarNotificacion(id); // Llama a la función para desactivar
      } else {
        await activarNotificacion(id); // Llama a la función para activar
      }
      setNotificacion((prev) => ({ ...prev, is_active: !prev.is_active })); // Cambia el estado de is_active
    } catch (error) {
      console.error('Error updating notificación status:', error);
    } finally {
      setLoadingStatus(false); // Desactivar el loader
    }
  };

  const getRepeatType = () => {
    switch (notificacion.repeat_type) {
      case 'daily':
        return `Cada día`;
      case 'weekly':
        return `Cada semana, día ${notificacion.repeat_day_of_week || ''}`;
      case 'monthly':
        return `Cada mes, día ${notificacion.repeat_day_of_month || ''}`;
      default:
        return 'No se repite';
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    </Box>;
  }

  if (!notificacion) {
    return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography variant="h6" color="textSecondary">
        No se encontró la notificación.
      </Typography>
    </Box>;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2, paddingTop: 0 }}>
      <Card sx={{ width: '100%', boxShadow: '0px 0px 8px rgba(211, 211, 211, 0.6)' }}>
        <CardContent>
          {/* Título de la notificación y botón de activación/desactivación */}
          <Box display="flex" alignItems="center" mb={2}>
            <IconButton onClick={handleGoBack} style={{ marginRight: "16px" }}>
              <ArrowBackIcon />
            </IconButton>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" fontWeight="bold">
                {notificacion.title}
              </Typography>
              <Button
                variant="contained"
                color={notificacion.is_active ? "error" : "success"}
                startIcon={loadingStatus ? <CircularProgress size={20} color="inherit" /> : <PowerSettingsNewIcon />}
                onClick={toggleNotificacionStatus}
                disabled={loadingStatus} // Deshabilita el botón mientras se carga
              >
                {loadingStatus ? (notificacion.is_active ? "Desactivando..." : "Activando...") : (notificacion.is_active ? "Desactivar Notificación" : "Activar Notificación")}
              </Button>
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Detalles de la Notificación */}
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            Detalles de la Notificación
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Tipo de Repetición
              </Typography>
              <Typography>{getRepeatType()}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Hora de Envío
              </Typography>
              <Typography>{notificacion.send_time}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Sucursales
              </Typography>
              <Typography>{notificacion.sucursales?.join(", ")}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Plantilla del Mensaje
              </Typography>
              <Typography>{notificacion.message_template}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Estado
              </Typography>
              <Chip label={notificacion.is_active ? "Notificación Activa" : "Inactiva"} color={notificacion.is_active ? "success" : "default"} size="small" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default NotificacionDetalle;
