import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  CircularProgress,
  Box,
  Paper,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import QuestionMarkIcon from "@mui/icons-material/HelpOutline";
import { getFeriados, deleteFeriado } from "../../services/api";
import { routesPaths } from "../../config/routes";

const Feriados = () => {
  const [filter, setFilter] = useState("");
  const [feriados, setFeriados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [feriadoToDelete, setFeriadoToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false); // Estado para indicar si está eliminando
  const [currentPage, setCurrentPage] = useState(1);
  const feriadosPerPage = 10; // Número de feriados por página
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFeriados = async () => {
      try {
        const response = await getFeriados();
        setFeriados(Array.isArray(response.data) ? response.data : []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchFeriados();
  }, []);

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );

  if (error) return <p>Error loading feriados.</p>;

  const handleEditClick = (feriado) => {
    navigate(`${routesPaths.feriados}/${feriado.id}/editar`, { state: { feriado } });
  };

  const handleDeleteClick = (feriado) => {
    setFeriadoToDelete(feriado);
    setOpenDialog(true);
  };

  const confirmDelete = async () => {
    setDeleting(true); // Inicia la eliminación
    try {
      await deleteFeriado(feriadoToDelete.id);
      setFeriados(feriados.filter((f) => f.id !== feriadoToDelete.id));
      setOpenDialog(false);
    } catch (err) {
      console.error("Error deleting feriado:", err);
    } finally {
      setDeleting(false); // Termina la eliminación
    }
  };

  const indexOfLastFeriado = currentPage * feriadosPerPage;
  const indexOfFirstFeriado = indexOfLastFeriado - feriadosPerPage;
  
  // Filtrar y paginar feriados
  const filteredFeriados = Array.isArray(feriados)
    ? feriados.filter((item) =>
        item.nombre.toLowerCase().includes(filter.toLowerCase())
      )
    : [];
    
  const currentFeriados = filteredFeriados.slice(indexOfFirstFeriado, indexOfLastFeriado);
  const totalPages = Math.ceil(filteredFeriados.length / feriadosPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <Container style={{ marginLeft: "0", maxWidth: "100%" }}>
      <h1 style={{ marginTop: 0 }}>Feriados</h1>
      <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginBottom: "16px" }}>
        <TextField
          label="Filtrar"
          variant="outlined"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/app/feriados/agregar")}
        >
          Agregar Feriado
        </Button>
      </Box>

      {filteredFeriados.length === 0 ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="50vh">
          <QuestionMarkIcon style={{ fontSize: 50, color: "gray" }} />
          <Typography variant="h6" color="textSecondary">
            No existen feriados guardados
          </Typography>
        </Box>
      ) : (
        <>
          <TableContainer component={Paper} elevation={0} sx={{ border: "1px solid lightgray", borderRadius: "8px", backgroundColor: "#fff" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Nombre</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Fecha</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentFeriados.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: "#fff",
                      "&:hover": { backgroundColor: "#f0f0f0" },
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{item.nombre}</TableCell>
                    <TableCell>{item.fecha}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditClick(item)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteClick(item)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Sección de paginación */}
          {filteredFeriados.length > feriadosPerPage && (
            <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop={2}>
              <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                Anterior
              </Button>
              <Typography style={{ margin: "0 16px" }}>
                Página {currentPage} de {totalPages}
              </Typography>
              <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
                Siguiente
              </Button>
            </Box>
          )}
        </>
      )}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas eliminar el feriado "{feriadoToDelete?.nombre}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary" disabled={deleting}>
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="primary" disabled={deleting}>
            {deleting ? <CircularProgress size={24} /> : "Confirmar"}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Feriados;
