// src/pages/RecordatorioDetalle.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getRecordatorio } from '../../services/api';
import { Box, Typography, Card, CardContent, Grid, Divider, CircularProgress, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const RecordatorioDetalle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [recordatorio, setRecordatorio] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecordatorio = async () => {
      try {
        const response = await getRecordatorio(id);
        setRecordatorio(response.data);
      } catch (error) {
        setError('Error fetching recordatorio');
      } finally {
        setLoading(false);
      }
    };

    fetchRecordatorio();
  }, [id]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    </Box>;
  }

  if (!recordatorio) {
    return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography variant="h6" color="textSecondary">
        No se encontró el recordatorio.
      </Typography>
    </Box>;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2, paddingTop: 0 }}>
      <Card sx={{ width: '100%', boxShadow: '0px 0px 8px rgba(211, 211, 211, 0.6)' }}>
        <CardContent>
          {/* Título del recordatorio y empresa */}
          <Box display="flex" alignItems="center" mb={2}>
          <IconButton onClick={handleGoBack} style={{ marginRight: "16px" }}>
            <ArrowBackIcon />
          </IconButton>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" fontWeight="bold">
                {recordatorio.title}
              </Typography>
            </Box>
          </Box>
          <Typography variant="body2" color="textSecondary">
            Empresa: {recordatorio.empresa}
          </Typography>

          <Divider sx={{ my: 2 }} />

          {/* Detalles del Recordatorio */}
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            Detalles del Recordatorio
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Tipo de Evento
              </Typography>
              <Typography>{recordatorio.event_type === "before_delivery" ? "Antes de la entrega" : recordatorio.event_type}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Tiempo de Envío
              </Typography>
              <Typography>{recordatorio.time_offset}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Hora de Envío
              </Typography>
              <Typography>{recordatorio.send_time}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Sucursales
              </Typography>
              <Typography>{recordatorio.sucursales?.join(", ")}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Plantilla del Mensaje
              </Typography>
              <Typography>{recordatorio.message_template}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default RecordatorioDetalle;
