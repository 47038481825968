import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  CircularProgress,
  Box,
  Grid,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import QuestionMarkIcon from "@mui/icons-material/HelpOutline"; // Icono de signo de interrogación
import { getRecordatorios } from "../../services/api"; // Importa la función del servicio

const Recordatorios = () => {
  const [filter, setFilter] = useState("");
  const [recordatorios, setRecordatorios] = useState([]); // Inicializa como un array vacío
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Número de elementos por página
  const totalPages = Math.ceil(recordatorios.length / itemsPerPage);

  useEffect(() => {
    const fetchRecordatorios = async () => {
      try {
        const response = await getRecordatorios();
        // Asegúrate de que la respuesta es un array antes de establecer el estado
        setRecordatorios(Array.isArray(response.data) ? response.data : []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchRecordatorios();
  }, []);

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );

  if (error) setError(true);

  const truncateMessage = (message, maxLength) => {
    return message.length > maxLength ? `${message.substring(0, maxLength)}...` : message;
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  return (
    <Container style={{ marginLeft: "0", maxWidth: "100%" }}>
      <h1 style={{ marginTop: 0 }}>Recordatorios</h1>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ marginBottom: "16px" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Filtrar"
            variant="outlined"
            fullWidth
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/app/recordatorios/agregar"
          >
            Agregar Recordatorio
          </Button>
        </Grid>
      </Grid>
      {recordatorios.length === 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="300px"
        >
          <QuestionMarkIcon style={{ fontSize: 60, color: "gray" }} />
          <Typography variant="h6" color="textSecondary">
            No existen recordatorios
          </Typography>
        </Box>
      ) : error ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="50vh">
          <ReportProblemOutlinedIcon style={{ fontSize: 50, color: "gray" }} />
          <Typography variant="h6" color="textSecondary">
            Error al cargar las notificaciones.
          </Typography>
        </Box>
    ) : (
        <>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              border: "1px solid lightgray",
              borderRadius: "8px",
              backgroundColor: "#fff",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Título</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Empresa</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Días antes</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Plantilla de Mensaje
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Sucursales</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recordatorios
                  .filter(
                    (item) =>
                      item.title.toLowerCase().includes(filter.toLowerCase()) ||
                      item.empresa.toLowerCase().includes(filter.toLowerCase())
                  )
                  .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                  .map((item, index) => (
                    <TableRow
                      key={index}
                      className="table-row-hover"
                      sx={{
                        backgroundColor: "#fff", // Color de fondo por defecto
                        "&:hover": {
                          backgroundColor: "#f0f0f0", // Color de fondo al hacer hover
                        },
                      }}
                    >
                      <TableCell>{item.title}</TableCell>
                      <TableCell>
                        {item.all_empresas ? "Todas las empresas" : item.empresa}
                      </TableCell>
                      <TableCell>{Math.abs(parseInt(item.time_offset))} días antes</TableCell>
                      <TableCell>{truncateMessage(item.message_template, 50)}</TableCell>
                      <TableCell>{item.sucursales.join(", ")}</TableCell>
                      <TableCell>
                        <IconButton
                          component={Link}
                          to={`/app/recordatorios/${item.id}`}
                        >
                          <ChevronRightIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Sección de paginación */}
          {recordatorios.length > itemsPerPage && ( // Ocultar si no hay suficientes recordatorios para paginar
            <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop={2}>
              <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                Anterior
              </Button>
              <Typography style={{ margin: "0 16px" }}>
                Página {currentPage} de {totalPages}
              </Typography>
              <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
                Siguiente
              </Button>
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default Recordatorios;
