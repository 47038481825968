import React, { useState } from "react";
import {
  Button,
  Container,
  TextField,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addFeriado } from "../../services/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import showToast from '../../services/toast';

const AddFeriado = () => {
  const [nombre, setNombre] = useState("");
  const [fecha, setFecha] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await addFeriado({ nombre, fecha });
      showToast.success('Feriado agregado correctamente');
      setNombre("");
      setFecha("");
      setTimeout(() => {
        navigate("/app/feriados");
      }, 1000);
    } catch (err) {
      setError(err);
      showToast.error('Error al agregar el feriado.');
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton onClick={handleGoBack} style={{ marginRight: "16px" }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          Agregar Feriado
        </Typography>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {error && <p>Error al agregar el feriado.</p>}
          <form onSubmit={handleSubmit}>
            <TextField
              label="Nombre"
              variant="outlined"
              fullWidth
              margin="normal"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
            />
            <TextField
              label="Fecha"
              type="date"
              variant="outlined"
              fullWidth
              margin="normal"
              value={fecha}
              onChange={(e) => setFecha(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
            />
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? "Agregando..." : "Agregar Feriado"}
              </Button>
            </Box>
          </form>
        </>
      )}
    </Container>
  );
};

export default AddFeriado;
