import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getRecordatorios, getNotifications, getFeriados } from '../../services/api'; 
import { Container, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles'; 
import { useNavigate } from 'react-router-dom'; 
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import showToast from '../../services/toast';
import { routesPaths } from "../../config/routes";

const localizer = momentLocalizer(moment);

const Calendario = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true); // Estado para controlar la carga
  const [error, setError] = useState(false); // Estado para manejar errores

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [recordatorios, notificaciones, feriados] = await Promise.all([
          getRecordatorios(),
          getNotifications(),
          getFeriados(), 
        ]);

        const combinedEvents = [
          ...(recordatorios.data || []).map((recordatorio) => ({
            id: recordatorio.id,
            title: recordatorio.title,
            start: moment().add(recordatorio.time_offset).toDate(),
            end: moment().add(recordatorio.time_offset).add(1, 'hour').toDate(),
            type: 'recordatorio',
            message_template: recordatorio.message_template,
            empresa: recordatorio.empresa,
            sucursales: recordatorio.sucursales.join(', '),
          })),
          ...(notificaciones.data || []).map((notificacion) => ({
            id: notificacion.id,
            title: notificacion.title,
            start: new Date(notificacion.start_datetime),
            end: moment(new Date(notificacion.start_datetime)).add(1, 'hour').toDate(),
            type: 'notificacion',
            message_template: notificacion.message_template,
            empresa: notificacion.empresa,
            sucursales: notificacion.sucursales.join(', '),
          })),
          ...(feriados.data || []).map((feriado) => ({
            id: feriado.id,
            title: feriado.nombre,
            start: moment(feriado.fecha).toDate(),
            end: moment(feriado.fecha).add(1, 'day').toDate(),
            type: 'feriado',
          })),
        ];

        setEvents(combinedEvents);
        setLoading(false); // Carga completa
      } catch (error) {
        console.error('Error fetching events:', error);
        setError(true); // Establece el estado de error
        showToast.error('Ocurrió un error al cargar los eventos.'); // Muestra el mensaje de error
        setLoading(false); // Carga completa aunque haya un error
      }
    };

    fetchData();
  }, []);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEvent(null);
  };

  const handleViewDetails = () => {
    if (selectedEvent) {
      const path = selectedEvent.type === 'recordatorio'
        ? routesPaths.recordatorioDetalle.replace(':id', selectedEvent.id)
        : selectedEvent.type === 'notificacion'
        ? routesPaths.notificacionDetalle.replace(':id', selectedEvent.id)
        : null;

      if (path) {
        navigate(path);
      }
    }
  };

  const renderEventTitle = (event) => {
    let icon;
    switch (event.type) {
      case 'recordatorio':
        icon = <NotificationsActiveOutlinedIcon style={{ marginRight: '8px' }} />;
        break;
      case 'notificacion':
        icon = <SendOutlinedIcon style={{ marginRight: '8px' }} />;
        break;
      case 'feriado':
        icon = <EventBusyIcon style={{ marginRight: '8px' }} />;
        break;
      default:
        icon = null;
    }

    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        {event.title}
      </span>
    );
  };

  return (
    <Container style={{ marginLeft: "0", maxWidth: "100%", height: '100vh' }}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
        <CircularProgress />
      </Box>
      ) : error ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="50vh">
        <ReportProblemOutlinedIcon style={{ fontSize: 50, color: "gray" }} />
        <Typography variant="h6" color="textSecondary">
          Error al cargar los eventos.
        </Typography>
      </Box>
      ) : (
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: '100%' }}
          onSelectEvent={handleSelectEvent}
          eventPropGetter={(event) => {
            const backgroundColor = event.type === 'feriado' 
              ? 'red' 
              : event.type === 'recordatorio' 
                ? '#FFD700' 
                : '#00BFFF';
            return { style: { backgroundColor } };
          }}
          components={{
            event: ({ event }) => renderEventTitle(event), // Renderiza el título con el ícono
          }}
        />
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle style={{ fontWeight: 'bold' }}>{selectedEvent?.title}</DialogTitle>
        <DialogContent>
          <p>{selectedEvent?.message_template}</p>
          {selectedEvent?.empresa && (
            <p><strong>Empresa:</strong> {selectedEvent?.empresa}</p>
          )}
          {selectedEvent?.sucursales && selectedEvent.sucursales.length > 0 && (
            <p><strong>Sucursal:</strong> {selectedEvent?.sucursales}</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseDialog} 
            color="primary" 
            variant="text"
          >
            Cerrar
          </Button>
          <Button 
            onClick={handleViewDetails} 
            color="primary" 
            variant="contained"
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            Ver detalles
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Calendario;
