import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField
} from "@mui/material";
import { diasSemana } from '../../../models/Utils';

const RepeticionControl = ({
  tipoRepeticion,
  repeticionTemp,
  setRepeticionTemp,
  setTipoRepeticion
}) => {

  return (
    <>
      <FormControl fullWidth style={{ marginBottom: "16px" }}>
        <InputLabel>Tipo de Repetición</InputLabel>
        <Select
          value={tipoRepeticion}
          onChange={(e) => {
            setTipoRepeticion(e.target.value); // Actualiza el tipo de repetición
            setRepeticionTemp({ dia: "", hora: "" }); // Resetea el estado temporal
          }}
        >
          <MenuItem value="daily">Diario</MenuItem>
          <MenuItem value="weekly">Semanal</MenuItem>
          <MenuItem value="monthly">Mensual</MenuItem>
          <MenuItem value="every_x_days">X Días</MenuItem>
          <MenuItem value="one_off">Una vez</MenuItem>
        </Select>
      </FormControl>

      {tipoRepeticion === "every_x_days" && (
        <FormControl fullWidth style={{ marginBottom: "16px" }}>
          <TextField
            label="Número de Días"
            type="number"
            value={repeticionTemp.dia || ""}
            onChange={(e) => setRepeticionTemp({ ...repeticionTemp, dia: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
      )}

      {(tipoRepeticion !== "daily" && tipoRepeticion !== "every_x_days" && tipoRepeticion !== "one_off") && (
        <Grid container alignItems="center" spacing={2} style={{ marginBottom: "16px" }}>
          <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Día</InputLabel>
                <Select
                  value={repeticionTemp.dia}
                  onChange={(e) => setRepeticionTemp({ ...repeticionTemp, dia: e.target.value })}
                >
                  {tipoRepeticion === "monthly" ? (
                    [...Array(31).keys()].map(day => (
                      <MenuItem key={day + 1} value={day + 1}>
                        {day + 1}
                      </MenuItem>
                    ))
                  ) : (
                    diasSemana.map(dia => (
                      <MenuItem key={dia.valor} value={dia.valor}>
                        {dia.nombre}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label="Hora de envío"
                type="time"
                value={repeticionTemp.hora || ""}
                onChange={(e) => setRepeticionTemp({ ...repeticionTemp, hora: e.target.value })}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }} // 5 minutos
              />
            </FormControl>
          </Grid>
        </Grid>
      )}

      {(tipoRepeticion === "daily" || tipoRepeticion === "every_x_days") && (
        <Grid container alignItems="center" spacing={2} style={{ marginBottom: "16px" }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Hora de envío"
                type="time"
                value={repeticionTemp.hora || ""}
                onChange={(e) => setRepeticionTemp({ ...repeticionTemp, hora: e.target.value })}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }} // 5 minutos
              />
            </FormControl>
          </Grid>
        </Grid>
      )}

      {tipoRepeticion === "one_off" && (
        <Grid container alignItems="center" spacing={2} style={{ marginBottom: "16px" }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Hora de envío"
                type="time"
                value={repeticionTemp.hora || ""}
                onChange={(e) => setRepeticionTemp({ ...repeticionTemp, hora: e.target.value })}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }} // 5 minutos
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RepeticionControl;
