import axios from "axios";

const api = axios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
  },
});


export const getRecordatorios = () => {
  const url = "/recordatorios/";
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const getRecordatorio = (id) => {
  const url = `/recordatorios/${id}/`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const addRecordatorio = (recordatorio) => {
  console.log(`Making POST request to: ${api.defaults.baseURL}/recordatorios/ with data:`, recordatorio);
  return api.post("/recordatorios/", recordatorio);
};


export const getNotifications = () => {
  const url = "/notificaciones/";
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const getNotificacion = (id) => {
  const url = `/notificaciones/${id}/`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const addNotificacion = (recordatorio) => {
  console.log(`Making POST request to: ${api.defaults.baseURL}/recordatorios/ with data:`, recordatorio);
  return api.post("/notificaciones/", recordatorio);
};

export const activarNotificacion = (id) => {
  const url = `/notificaciones/${id}/activate/`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.patch(url);
};

export const desactivarNotificacion = (id) => {
  const url = `/notificaciones/${id}/deactivate/`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.patch(url);
};

// utils

export const getEmpresas = () => {
  const url = "/utils/listar_titulares/";
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const getSucursales = (ruc) => {
  const url = `/utils/listar_sucursales/?ruc=${ruc}`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const getFeriados = () => {
  const url = "/utils/feriados/";
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const addFeriado = (feriado) => {
  console.log(`Making POST request to: ${api.defaults.baseURL}/feriados/ with data:`, feriado);
  return api.post("/utils/feriados/", feriado);
};

export const editFeriado = (id, feriado) => {
  console.log(`Making POST request to: ${api.defaults.baseURL}/feriados/id with data:`, feriado);
  return api.put(`/utils/feriados/${id}/`, feriado);
};

export const deleteFeriado = (id) => {
  console.log(`Making POST request to: ${api.defaults.baseURL}/feriados/id`,);
  return api.delete(`/utils/feriados/${id}/`);
};

export const getVariables = () => {
  const url = "/notificaciones/template-variables/";
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export default api;
